
import { defineComponent, ref, onMounted, onActivated, computed } from "vue";
import { MBDatatablePlus } from "magicbean-saas-common";
import FilterDropdown from "@/views/sales-order/order-overview/FilterDropdown.vue";
import productDefaultTable from "@/components/table/ProductDefault.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiSalesOrders } from "@/core/api";
import _ from "lodash";
import mixin from "@/mixins";
import fileDownload from "js-file-download";
import { useI18n } from "vue-i18n";
import { SalesOrderTable } from "@/core/directive/interface/salesOrder";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import { NumberOrString } from "@/core/directive/type/common";
import {
  getSalesOrderFulfillmentStatus,
  getSalesOrderPriorityLevelMap,
  getSalesOrderPriorityLevelSvgStyleMap,
  getSalesOrderStatusMap,
  getSalesOrderTypeOptions,
  getSettlementStatusMap,
} from "@/core/directive/function/salesOrder";
import store from "@/store";
import {
  SalesOrderNewStatus,
  SalesOrderPriorityLevel,
  SalesOrderSettlementStatus,
} from "@/core/directive/type/salesOrder";
import {
  CommonArrayToString,
  commonChangeFilterAddDate,
  commonExportFile,
  formatDate,
  formatDateTime,
  setModuleBCN,
  firstnameToPinyin,
  lastnameToPinyin,
} from "@/core/directive/function/common";
import { isAuditView } from "@/core/directive/function/order";
import { useRoute } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";
import WindowResize from "@/components/layout/WindowResize.vue";

export default defineComponent({
  name: "sales-order-order-overview",
  components: {
    MBDatatablePlus,
    productDefaultTable,
    FilterDropdown,
    PermissionCommon,
    WindowResize,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const { showServerErrorMsg } = mixin();

    const loading = ref(true);
    const tableData = ref<Array<SalesOrderTable>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const disabledImportOrders = ref<boolean>(false);
    const disabledExportSalesProfilling = ref<boolean>(false);
    const disabledExportOrderCombination = ref<boolean>(false);
    const disabledExportSalesOrderItemList = ref<boolean>(false);
    const disabledExportSalesFinancial = ref<boolean>(false);
    const disabledExport = ref<boolean>(false);
    const disabledExportSku = ref<boolean>(false);
    const disabledExportMmaOrder = ref<boolean>(false);
    const options = ref({
      store_value: "1,9,11,12,13,14,15,17,18,19,20,21,23,24",
      store_id: [
        {
          label: t("salesOrderOverview.all"),
          value: "1,9,11,12,13,14,15,17,18,19,20,21,23,24",
        },
        {
          label: t("common.fullPaymentOrder"),
          value: "1,9,11,12,13,15,17,18,20",
        },
        {
          label: t("common.wholesaleOrders"),
          value: "14",
        },
        // {
        //   label: t("common.depositOrders"),
        //   value: "19",
        // },
        {
          label: t("common.depositOrders"),
          value: "19,24",
        },
      ],
      status_value: [
        String(SalesOrderNewStatus.New),
        String(SalesOrderNewStatus.Submit),
        String(SalesOrderNewStatus.Verified),
        String(SalesOrderNewStatus.Processing),
        String(SalesOrderNewStatus.FulfillmentCompleted),
        String(SalesOrderNewStatus.OrderCompleted),
        String(SalesOrderNewStatus.Refunded),
      ],
    });
    const filterOptions = ref<Array<FilterOption>>([
      {
        field: "store_id",
        value: options.value.store_value.split(","), // 1:普通订单；14：批采单；16：double sale；19：定金单
        condition: "in",
      },
      {
        field: "status",
        value: options.value.status_value,
        condition: "in",
      },
    ]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "order_date", direction: "desc" },
    ]);

    const tableHeader = ref([
      {
        name: t("salesOrder.orderId"),
        key: "order_number",
        sortable: true,
      },
      {
        name: t("salesOrder.orderDateTime"),
        key: "order_date",
        sortable: true,
      },
      {
        name: t("salesOrder.priority"),
        key: "is_priority",
        sortable: false,
      },
      {
        name: t("salesOrder.deliveryDate"),
        key: "delivery_date",
        sortable: false,
      },
      {
        name: t("order.customer"),
        key: "customer",
        sortable: false,
      },
      {
        name: t("order.articles"),
        key: "articles",
        sortable: false,
      },
      {
        name: t("order.qty"),
        key: "ordered_qty",
        sortable: true,
        align: "right",
      },
      {
        name: t("salesOrder.grandTotalBase"),
        key: "grand_total_base",
        sortable: true,
        align: "right",
      },
      {
        name: t("salesOrder.status"),
        key: "order_status",
        sortable: false,
        align: "right",
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    if (isAuditView.value) {
      tableHeader.value = tableHeader.value.filter(
        (item) => item.key !== "is_priority" && item.key !== "delivery_date"
      );
    }

    const getIsRealOrder = computed(() => {
      let arr: FilterOption[] = [];
      // NOTE: 废弃
      // if (options.value.store_value === "19") {
      //   arr.push({
      //     field: "is_real_order",
      //     value: "1", // real deposit orders
      //     condition: "eq",
      //   });
      // }
      return arr;
    });

    const init = () => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getSalesOrderList();
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    const fileExportSalesProfilling = () => {
      if (disabledExportSalesProfilling.value) true;
      disabledExportSalesProfilling.value = true;
      ApiSalesOrders.exportSalesProfilling({
        search_value: search.value,
        filter_group: [...filterOptions.value, ...getIsRealOrder.value],
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportSalesProfilling.value = false;
          // const disposition = data.headers["content-disposition"];
          // fileDownload(data.data, disposition.split("filename=")[1]);
          let fileReader = new FileReader();
          fileReader.onload = function () {
            try {
              // 说明是普通对象数据，后台转换失败
              const jsonData = JSON.parse(this.result as string);
              showServerErrorMsg(jsonData);
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              // const disposition = data.headers["content-disposition"];
              // fileDownload(data.data, disposition.split("filename=")[1]);
              fileDownload(data.data, "Sales Order Profilling.xlsx");
            }
          };
          fileReader.readAsText(data.data);
        })
        .catch((error) => {
          disabledExportSalesProfilling.value = false;
          console.log(error);
        });
    };

    const fileExportOrderCombination = () => {
      if (disabledExportOrderCombination.value) true;
      disabledExportOrderCombination.value = true;
      ApiSalesOrders.exportOrderCombination({
        search_value: search.value,
        filter_group: [...filterOptions.value, ...getIsRealOrder.value],
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportOrderCombination.value = false;
          // const disposition = data.headers["content-disposition"];
          // fileDownload(data.data, disposition.split("filename=")[1]);
          let fileReader = new FileReader();
          fileReader.onload = function () {
            try {
              // 说明是普通对象数据，后台转换失败
              const jsonData = JSON.parse(this.result as string);
              showServerErrorMsg(jsonData);
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              // const disposition = data.headers["content-disposition"];
              // fileDownload(data.data, disposition.split("filename=")[1]);
              fileDownload(data.data, "Sales Order Profilling.zip");
            }
          };
          fileReader.readAsText(data.data);
        })
        .catch((error) => {
          disabledExportOrderCombination.value = false;
          console.log(error);
        });
    };

    const fileExportSalesOrderItemList = () => {
      if (disabledExportSalesOrderItemList.value) true;
      disabledExportSalesOrderItemList.value = true;
      ApiSalesOrders.exportSalesOrderItemList({
        search_value: search.value,
        filter_group: [...filterOptions.value, ...getIsRealOrder.value],
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportSalesOrderItemList.value = false;
          // const disposition = data.headers["content-disposition"];
          // fileDownload(data.data, disposition.split("filename=")[1]);
          let fileReader = new FileReader();
          fileReader.onload = function () {
            try {
              // 说明是普通对象数据，后台转换失败
              const jsonData = JSON.parse(this.result as string);
              showServerErrorMsg(jsonData);
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              // const disposition = data.headers["content-disposition"];
              // fileDownload(data.data, disposition.split("filename=")[1]);
              fileDownload(data.data, "Sales Order Details.xlsx");
            }
          };
          fileReader.readAsText(data.data);
        })
        .catch((error) => {
          disabledExportSalesOrderItemList.value = false;
          console.log(error);
        });
    };

    const fileExportSalesFinancial = () => {
      if (disabledExportSalesFinancial.value) true;
      disabledExportSalesFinancial.value = true;
      ApiSalesOrders.exportSalesFinancial({
        search_value: search.value,
        filter_group: [...filterOptions.value, ...getIsRealOrder.value],
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportSalesFinancial.value = false;
          // const disposition = data.headers["content-disposition"];
          // fileDownload(data.data, disposition.split("filename=")[1]);
          let fileReader = new FileReader();
          fileReader.onload = function () {
            try {
              // 说明是普通对象数据，后台转换失败
              const jsonData = JSON.parse(this.result as string);
              showServerErrorMsg(jsonData);
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              // const disposition = data.headers["content-disposition"];
              // fileDownload(data.data, disposition.split("filename=")[1]);
              fileDownload(data.data, "Sales Order Financial.xlsx");
            }
          };
          fileReader.readAsText(data.data);
        })
        .catch((error) => {
          disabledExportSalesOrderItemList.value = false;
          console.log(error);
        });
    };

    const fileExport = () => {
      if (disabledExport.value) true;
      disabledExport.value = true;
      ApiSalesOrders.export({
        search_value: search.value,
        filter_group: [...filterOptions.value, ...getIsRealOrder.value],
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExport.value = false;
          // const disposition = data.headers["content-disposition"];
          // fileDownload(data.data, disposition.split("filename=")[1]);
          let fileReader = new FileReader();
          fileReader.onload = function () {
            try {
              // 说明是普通对象数据，后台转换失败
              const jsonData = JSON.parse(this.result as string);
              showServerErrorMsg(jsonData);
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              // const disposition = data.headers["content-disposition"];
              fileDownload(data.data, "Sales Order Report (Order ID).xlsx");
            }
          };
          fileReader.readAsText(data.data);
        })
        .catch((error) => {
          disabledExport.value = false;
          console.log(error);
        });
    };

    const fileExportSku = () => {
      if (disabledExportSku.value) true;
      disabledExportSku.value = true;
      ApiSalesOrders.exportSku({
        search_value: search.value,
        filter_group: [...filterOptions.value, ...getIsRealOrder.value],
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportSku.value = false;
          // const disposition = data.headers["content-disposition"];
          // fileDownload(data.data, disposition.split("filename=")[1]);
          let fileReader = new FileReader();
          fileReader.onload = function () {
            try {
              // 说明是普通对象数据，后台转换失败
              const jsonData = JSON.parse(this.result as string);
              showServerErrorMsg(jsonData);
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              // const disposition = data.headers["content-disposition"];
              fileDownload(data.data, "Sales Order Report (SKU).xlsx");
            }
          };
          fileReader.readAsText(data.data);
        })
        .catch((error) => {
          disabledExportSku.value = false;
          console.log(error);
        });
    };

    const fileExportMmaOrder = async () => {
      if (disabledExportMmaOrder.value) true;
      disabledExportMmaOrder.value = true;
      const data = await ApiSalesOrders.exportMmaOrder({
        search_value: search.value,
        filter_group: [...filterOptions.value, ...getIsRealOrder.value],
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      });
      disabledExportMmaOrder.value = false;
      commonExportFile(data);
    };

    const getSalesOrderList = async () => {
      loading.value = true;
      const { data } = await ApiSalesOrders.getSalesOrderList({
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        filter_group: [...filterOptions.value, ...getIsRealOrder.value],
        sort_orders: sortOptions.value,
      });
      loading.value = false;
      if (data.code == 0) {
        tableData.value.splice(0, tableData.value.length, ...data.data.items);
        total.value = data.data.total;
      }
    };

    const updateList = () => {
      getSalesOrderList();
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getSalesOrderList();
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const getLabel = (val, key) => {
      return options.value[key].find((item) => {
        return item.value == val;
      })?.label;
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getSalesOrderList();
    };

    const storeChange = (e) => {
      filterOptions.value[0].value = e.split(",");
      searchItems();
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (item == "settlement_status") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "in",
            });
          } else if (item == "status") {
            filterOptions.value[1].value = val[item];
          } else if (item == "order_date_start") {
            filterArr.push({
              field: "order_date",
              value: commonChangeFilterAddDate(val[item], 1),
              condition: "gteq",
            });
          } else if (item == "order_date_end") {
            filterArr.push({
              field: "order_date",
              value: commonChangeFilterAddDate(val[item]),
              condition: "lteq",
            });
          } else if (
            item == "customer_id" ||
            item == "priority_level" ||
            item == "purchase_information" ||
            item == "brand_id"
          ) {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          }
        }
      }
      filterOptions.value.splice(2, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getSalesOrderList();
    };

    const handleFilterReset = () => {
      resetFilter();
      getSalesOrderList();
    };

    const resetFilter = () => {
      filterOptions.value.splice(2, filterOptions.value.length);
      filterOptions.value[1].value = options.value.status_value;
    };

    const debounceSearch = _.debounce(getSalesOrderList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch();
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const getOrderType = computed(() => {
      return (status: number) => {
        return getSalesOrderTypeOptions(status, t);
      };
    });

    const orderStatus = computed(() => {
      return (status: number) => {
        return getSalesOrderStatusMap(status, t)?.color;
      };
    });

    const fulfillmentStatus = computed(() => {
      return (status: NumberOrString) => {
        return getSalesOrderFulfillmentStatus(status, t);
      };
    });

    const settlementStatus = computed(() => {
      return (status: number) => {
        return getSettlementStatusMap(status, t)?.color;
      };
    });

    return {
      t,
      CommonArrayToString,
      commonChangeFilterAddDate,
      SalesOrderPriorityLevel,
      SalesOrderSettlementStatus,
      formatDate,
      formatDateTime,
      getSalesOrderPriorityLevelMap,
      getSalesOrderPriorityLevelSvgStyleMap,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      disabledImportOrders,
      disabledExportSalesProfilling,
      disabledExportOrderCombination,
      disabledExportSalesOrderItemList,
      disabledExportSalesFinancial,
      disabledExport,
      disabledExportSku,
      disabledExportMmaOrder,
      options,
      getSalesOrderList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      getLabel,
      searchItems,
      storeChange,
      handleFilter,
      handleFilterReset,
      updateList,
      removeSearch,
      getOrderType,
      orderStatus,
      fulfillmentStatus,
      settlementStatus,
      fileExportSalesProfilling,
      fileExportOrderCombination,
      fileExportSalesOrderItemList,
      fileExportSalesFinancial,
      fileExport,
      fileExportSku,
      fileExportMmaOrder,
      isAuditView,
      firstnameToPinyin,
      lastnameToPinyin,
    };
  },
});
